import msalAuthHandler from "vue-msal-wrapper";
import store from "../store/store";

export const DIPAuth = {
  install(Vue, options) {
    let http = msalAuthHandler.authenticatedApi(
      options.apiUri,
      "dip",
      {
        "X-Functions-Key": options.functionKey,
        "Accept": "application/json, text/plain, */*"
      }
    );

    http.interceptors.response.use(
      (response) => {
        // store.commit("setApiError");
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    Vue.prototype.$dip = http;
  }
};
