import msalAuthHandler from "vue-msal-wrapper"
import store from "../store/store"

export const CDSAuth = {
  install(Vue, options) {
    let http = msalAuthHandler.authenticatedApi(
      "https://adsprapiman-aue.azure-api.net/",
      "cds",
      { "Ocp-Apim-Subscription-Key": "3d679d8157174e8dbf7c1d15920433c6" }
    );

    http.interceptors.response.use(
      (response) => {
        // store.commit("setApiError");
        return response;
      },
      (error) => {
        // handleError(error);
        console.log("ADS AUTHENTICATION FAILED...");
        return Promise.reject(error);
      }
    );

    Vue.prototype.$cds = http;
  },
};


