import Vue from "vue";

import posthog from 'posthog-js'

// posthog.init('phc_8GYq6XKXWLn5XwaH25HyrLpHAjzXbzaMpnWoi2uY2Mo', { api_host: 'https://posthog.insights.arup.com' })

export default {
  methods: {
    displayMessage: function (val) {
      console.log("val");
      console.log(val);
    },

    async prebidUpdateDIP(store, url) {
      // if a plan has not been opened in the current session and there is nothing stored in 
      // store, do not try to send a request, this makes the timeout popup show when it shouldn't be.
      if (store.ProjectCode === null) {
        return;
      }

      try {
        const response = await Vue.prototype.$dip.post(`${url}/UpdatePreBidResult`, store);
        console.log(response.data);
      } catch (error) {
        console.log(error.response);
      }
    },

    async packetUpdateDIP(store, url, questionKey) { // create new dip dialog has loading issue
      // store == this.$store.state.plan 
      let result = null;
      sessionStorage.clear()
      console.log("local store", store)
      if (store.ProjectCode === null) {
        return;
      }
      await Vue.prototype.$dip
        .post(`${url}/UpdateResultQuestion`, {
          _id: store._id,
          ProjectCode: store.ProjectCode,
          QuestionKey: questionKey,
          Payload: {
            Content: store.DigitalPlan[questionKey]
          },
        })
        .then((res) => {
          console.log("response data", res.data);
          result = res.data;
          this.$store.commit("updateSaveNotification", true);
        })
        .catch((err) => {
          console.log(err.response);
        })
        .then((res) => {
          // posthog.capture('DIP has been saved event', { projectCode: store.ProjectCode, completionStatus: store.CompleteScore, updatedBy: store.ModifiedBy.Email })
          //  this.loading = false;

        });
      console.log(result)
      return result.DigitalPlan;

    },


    async fullUpdateDIP(store, url) { // create new dip dialog has loading issue
      // this.loading = true;
      sessionStorage.clear()
      this.checkPreparationScore(store);
      this.checkPreworkScore(store);
      this.checkWorkshopScore(store);
      this.checkActionScore(store);

      // this will save the plan
      // perform request

      // if a plan has not been opened in the current session and there is nothing stored in 
      // store, do not try to send a request, this makes the timeout popup show when it shouldnt be.
      console.log("local store", store)
      if (store.ProjectCode === null) {
        return;
      }
      await Vue.prototype.$dip
        .post(`${url}/UpdateResult`, store)
        .then((res) => {
          console.log("response data", res.data);
        })
        .catch((err) => {
          console.log(err.response);

        })
        .then((res) => {
          // posthog.capture('DIP has been saved event', { projectCode: store.ProjectCode, completionStatus: store.CompleteScore, updatedBy: store.ModifiedBy.Email })
          //  this.loading = false;
        });
    },


    checkPreparationScore(store) {
      if (store.DigitalPlan.PreparationComplete == 0) {
        if (
          (store.DigitalPlan.ClientAims.length > 0 ||
            store.DigitalPlan.TeamAims.length > 0 ||
            store.DigitalPlan.PainPoints.length > 0) &&
          (store.DigitalPlan.Contract == "true" ||
            store.DigitalPlan.ContractPersonResponsible != null)
        ) {
          store.CompleteScore += 20;
          store.DigitalPlan.PreparationComplete = 1;
          //   console.log("adding preparation score")
        }
      }
    },

    checkPreworkScore(store) {
      if (store.DigitalPlan.PreWorkComplete == 0) {
        if (
          store.DigitalPlan.ArupResourceFindings.length > 0 ||
          store.DigitalPlan.ExternalSoftwareFindings.length > 0 ||
          store.DigitalPlan.DataRequirements.length > 0
        ) {
          store.CompleteScore += 20;
          store.DigitalPlan.PreWorkComplete = 1;
          //   console.log("adding prework score")
        }
      }
    },

    checkWorkshopScore(store) {
      if (store.DigitalPlan.WorkshopComplete == 0) {
        if (
          store.DigitalPlan.ChallengesOpportunities.length > 0 ||
          store.DigitalPlan.Actionlength > 0
        ) {
          store.CompleteScore += 20;
          store.DigitalPlan.WorkshopComplete = 1;
          //   console.log("adding workshop score")
        }
      }
    },

    checkActionScore(store) {
      if (store.DigitalPlan.ActionsComplete == 0) {
        let check = [];
        store.DigitalPlan.ActionPlan.forEach((element) => {
          if (element.DeadlineDate !== null) {
            check.push(element.DeadlineDate);
          }
        });
        if (
          check.length == store.DigitalPlan.Actionlength &&
          store.DigitalPlan.Actionlength > 0
        ) {
          store.CompleteScore += 20;
          store.DigitalPlan.ActionsComplete = 1;
          //   console.log("adding actions score")
        }
      }
    },

    // example: loadDIPWithCode(ProjectCode, this.$store.state.ApiUri, this.$store.state.plan)
    //          this.$session.set("planData", this.$store.state.plan);
    async fullLoadGenericPlanWithCode(code, url, store, type) {
      let lastModified = null;
      let loadedStore = null;

      try {
        let response;
        if (type === "PREBID") {
          response = await Vue.prototype.$dip.get(
            `${url}/GetPreBidResults?ProjectCode=${code}&DataLevel=Full`
          );
        } else {
          response = await Vue.prototype.$dip.get(
            `${url}/GetResults?JobCode=${code}&DataLevel=Full`
          );
        }



        // Set response to the store plan object
        loadedStore = response.data[0];

        // if type equals DIP and DIP does not have new properties on data model, add them:

        if (type === "DIP") {
          if (!("ArupDigitalServices" in loadedStore.DigitalPlan)) {
            loadedStore.DigitalPlan["ArupDigitalServices"] = []
          }

          if (!("ArupValueStories" in loadedStore.DigitalPlan)) {
            loadedStore.DigitalPlan["ArupValueStories"] = []
          }

          if (!("DatasetStatus" in loadedStore.DigitalPlan)) {
            loadedStore.DigitalPlan["DatasetStatus"] = null
          }
          if (!("MetadataStatus" in loadedStore.DigitalPlan)) {
            loadedStore.DigitalPlan["MetadataStatus"] = null
          }
          if (!("MetadataStandards" in loadedStore.DigitalPlan)) {
            loadedStore.DigitalPlan["MetadataStandards"] = null
          }
          if (!("MetadataContact" in loadedStore.DigitalPlan)) {
            loadedStore.DigitalPlan["MetadataContact"] = {
              Name: null,
              Email: null,
            };
          }
          if (!("DatasetContact" in loadedStore.DigitalPlan)) {
            loadedStore.DigitalPlan["DatasetContact"] = {
              Name: null,
              Email: null,
            };
          }
        }

        // get information from parent if type equals DIP and field is null
        if (type == "DIP") {
          if (!loadedStore.Client || loadedStore.Client === null) {
            loadedStore.Client = await this.getFieldFromParent(code, "Projects", "CommissioningClientName")
          }

          if (!loadedStore.DigitalPlan.CreatedBy || loadedStore.DigitalPlan.CreatedBy === null) {
            loadedStore.DigitalPlan.CreatedBy = loadedStore.DigitalPlan.Owner
          }

          if (!loadedStore.JobCode || loadedStore.JobCode === null) {
            loadedStore.JobCode = loadedStore.ProjectCode;
          }

          if (!loadedStore.JobNumber || loadedStore.JobNumber === null) {
            loadedStore.JobNumber = loadedStore.ProjectCode.substring(0, 6);
          }
        }

        if (loadedStore)
          // check if new
          // Set last modified to the last person who edited this on load
          lastModified = loadedStore.ModifiedBy.Name;
        return { lastModified, loadedStore };
      } catch (error) {
        console.log(error);
        console.log("Error loading DIP.");
        throw error;
      }
    },

    async getFieldFromParent(code, entity, property) {
      let projectCode = code.slice(0, 6) + "00";
      console.log("projectCode", projectCode);
      console.log("jobcode", code);

      try {
        const response = await Vue.prototype.$cds.get(`cds/odata/${entity}`, {
          params: {
            $filter: `ProjectCode eq '${projectCode}'`,
            $select: property,
          },
        });
        console.log("response", response);
        return response.data.value[0][property];
      } catch (err) {
        console.log("issue with getting data from parent", err);
        return null;
      }
    },


    prebidLockCheck(plan, state) {
      let attendanceList = [];
      let planAccess = true;
      let showWarningDialog = false;

      // add owner to list only if not on it already
      if (plan.Owner.Email != null) {
        if (!attendanceList.includes(plan.Owner.Email)) {
          attendanceList.push(plan.Owner.Email);
        }
      }

      // add PM to list only if not on it already
      if (plan.ProjectManager.Email != null) {
        if (!attendanceList.includes(plan.ProjectManager.Email)) {
          attendanceList.push(plan.ProjectManager.Email);
        }
      }

      // add PD to list only if not on it already
      if (plan.ProjectDirector.Email != null) {
        if (!attendanceList.includes(plan.ProjectDirector.Email)) {
          attendanceList.push(plan.ProjectDirector.Email);
        }
      }

      if (
        plan.PreBidPlan.Lock == true &&
        !attendanceList.includes(state.user.unique_name)
      ) {
        console.log("not allowed");
        // loading = false;
        // warningDialog = true;
        // model = null;
        // entries = [];
        planAccess = false;
        showWarningDialog = true;

        return { planAccess, showWarningDialog };
      } else {
        planAccess = true;
        showWarningDialog = false;
        return { planAccess, showWarningDialog };
      }
    },

    dipLockCheck(plan, state) {
      let attendanceList = [];
      let planAccess = true;
      let showWarningDialog = false;

      // add owner to list only if not on it already

      if (plan.Owner.Email != null) {
        if (!attendanceList.includes(plan.Owner.Email)) {
          attendanceList.push(plan.Owner.Email);
        }
      }
      // add PM to list only if not on it already
      if (plan.ProjectManager.Email != null) {
        if (!attendanceList.includes(plan.ProjectManager.Email)) {
          attendanceList.push(plan.ProjectManager.Email);
        }
      }
      // add PD to list only if not on it already
      if (plan.ProjectDirector.Email != null) {
        if (!attendanceList.includes(plan.ProjectDirector.Email)) {
          attendanceList.push(plan.ProjectDirector.Email);
        }
      }
      // add Digital Lead to list only if not on it already
      if (plan.DigitalPlan.DigitalLead.Email != null) {
        if (!attendanceList.includes(plan.DigitalPlan.DigitalLead.Email)) {
          attendanceList.push(plan.DigitalPlan.DigitalLead.Email);
        }
      }
      // add Digital Facilitator to list only if not on it already
      if (plan.DigitalPlan.DigitalFacilitator.Email != null) {
        if (
          !attendanceList.includes(plan.DigitalPlan.DigitalFacilitator.Email)
        ) {
          attendanceList.push(plan.DigitalPlan.DigitalFacilitator.Email);
        }
      }

      // add all Discipline Leads to list only if not on it already
      if (plan.DigitalPlan.DisciplineLeads.length > 0) {
        plan.DigitalPlan.DisciplineLeads.forEach((element) => {
          if (!attendanceList.includes(element.Lead.Email)) {
            attendanceList.push(element.Lead.Email);
          }
        });
      }

      // add all Other Attendees to list only if not on it already
      if (plan.DigitalPlan.OtherAttendees.length > 0) {
        plan.DigitalPlan.OtherAttendees.forEach((element) => {
          if (!attendanceList.includes(element.Email)) {
            attendanceList.push(element.Email);
          }
        });
      }

      // check if the DIP is locked and that the user is in the list
      // if DIP is locked and user is not in list. notify user with dialog.
      if (
        plan.DigitalPlan.Lock == true &&
        !attendanceList.includes(this.$msal.getUser().username)
      ) {
        console.log("not allowed");
        planAccess = false;
        showWarningDialog = true;
        return { planAccess, showWarningDialog, attendanceList };
      } else {
        planAccess = true;
        showWarningDialog = false;
        return { planAccess, showWarningDialog };
      }
    },

    // example usage: loadGenericPlan(this.$store.state.ApiUri, model, this.$store.state)
    // this.$session.set("planData", this.$store.state.prebidPlan);
    //           // push to a preparation page.
    //           this.$router.push({ name: "PrebidActions" });
    async loadGenericPlan(url, model, store) {
      let lastModified = null;
      let loadedStore = null;
      let planAccess = null;
      let showWarningDialog = null;
      let attendanceList = [];

      if (model.hasOwnProperty("PreBidPlan")) {
        try {
          const res = await Vue.prototype.$dip.get(
            `${url}/GetPreBidResults?ProjectCode=` +
            model.ProjectCode +
            "&DataLevel=Full"
          );

          const lockCheck = this.prebidLockCheck(res.data[0], store);
          planAccess = lockCheck.planAccess;
          showWarningDialog = lockCheck.showWarningDialog;

          if (!planAccess) {
            return {
              planAccess: planAccess,
              showWarningDialog: showWarningDialog,
              lastModified: null,
              loadedStore: null,
              type: "PREBID",
            };
          } else {
            loadedStore = res.data[0];
            lastModified = loadedStore.ModifiedBy.Name;

            console.log("PREBID loaded.");
            return {
              planAccess: planAccess,
              showWarningDialog: showWarningDialog,
              lastModified: lastModified,
              loadedStore: loadedStore,
              type: "PREBID",
            };
          }
        } catch (err) {
          console.log(err);
          console.log("Error loading Prebid.");
        }
      } else {
        try {
          const res = await Vue.prototype.$dip.get(
            `${url}/GetResults?JobCode=` +
            model.JobCode +
            "&DataLevel=Full"
          );

          const lockCheck = this.dipLockCheck(res.data[0], store);
          planAccess = lockCheck.planAccess;
          showWarningDialog = lockCheck.showWarningDialog;
          attendanceList = lockCheck.attendanceList

          if (!planAccess) {
            return {
              planAccess: planAccess,
              showWarningDialog: showWarningDialog,
              lastModified: null,
              loadedStore: null,
              attendanceList: attendanceList,
              type: "DIP",
            };
          } else {
            loadedStore = res.data[0];

            // if existing DIP does not have new properties on data model, add them:

        if (!("ArupDigitalServices" in loadedStore.DigitalPlan)) {
          loadedStore.DigitalPlan["ArupDigitalServices"] = []
        }

        if (!("ArupValueStories" in loadedStore.DigitalPlan)) {
          loadedStore.DigitalPlan["ArupValueStories"] = []
        }

        if (!("DatasetStatus" in loadedStore.DigitalPlan)) {
          loadedStore.DigitalPlan["DatasetStatus"] = null
        }
        if (!("MetadataStatus" in loadedStore.DigitalPlan)) {
          loadedStore.DigitalPlan["MetadataStatus"] = null
        }
        if (!("MetadataStandards" in loadedStore.DigitalPlan)) {
          loadedStore.DigitalPlan["MetadataStandards"] = null
        }
        if (!("MetadataContact" in loadedStore.DigitalPlan)) {
          loadedStore.DigitalPlan["MetadataContact"] = {
            Name: null,
            Email: null,
          };
        }
        if (!("DatasetContact" in loadedStore.DigitalPlan)) {
          loadedStore.DigitalPlan["DatasetContact"] = {
            Name: null,
            Email: null,
          };
        }

        // check if DIP has empty fields and populate them based on Project-Level data

        if (!loadedStore.Client || loadedStore.Client === null) {
          loadedStore.Client = await this.getFieldFromParent(loadedStore.ProjectCode, "Projects", "CommissioningClientName")
        }

        if (!loadedStore.DigitalPlan.CreatedBy || loadedStore.DigitalPlan.CreatedBy === null) {
          loadedStore.DigitalPlan.CreatedBy = loadedStore.DigitalPlan.Owner
        }

        if (!loadedStore.JobCode || loadedStore.JobCode === null) {
          loadedStore.JobCode = loadedStore.ProjectCode;
        }

        if (!loadedStore.JobNumber || loadedStore.JobNumber === null) {
          loadedStore.JobNumber = loadedStore.ProjectCode.substring(0, 6);
        }

            lastModified = loadedStore.ModifiedBy.Name;

            console.log("DIP loaded.");
            return {
              planAccess: planAccess,
              showWarningDialog: showWarningDialog,
              lastModified: lastModified,
              loadedStore: loadedStore,
              attendanceList: attendanceList,
              type: "DIP",
            };
          }
        } catch (err) {
          console.log(err);
          console.log("Error loading DIP.");
        }
      }
    },

  },
};
