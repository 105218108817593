import Vue from "vue";
import VueRouter from "vue-router";

const Home = () => import("../views/Home.vue");
const Preparation = () => import("../views/Preparation.vue");
const Actions = () => import("../views/Actions.vue");
const Report = () => import("../views/Report.vue");
const ResearchPreWork = () => import("../views/ResearchPreWork.vue");
const Workshop = () => import("../views/Workshop.vue");
const Help = () => import("../views/Help.vue");
const Prebid = () => import("../views/Prebid.vue");
const PrebidActions = () => import("../views/PrebidActions.vue");
const PrebidReport = () => import("../views/PrebidReport.vue");
const Maintenance = () => import("../views/Maintenance.vue");


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/actions",
    name: "Actions",
    component: Actions,
    meta: {
      requireAuth: true,
    },
  },

  {
    path: "/preparation/:tab?",
    name: "Preparation",
    component: Preparation,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/report",
    name: "Report",
    component: Report,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/researchprework/:tab?",
    name: "ResearchPreWork",
    component: ResearchPreWork,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/workshop/:tab?",
    name: "Workshop",
    component: Workshop,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/prebidform",
    name: "PrebidForm",
    component: Prebid,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/prebidactions",
    name: "PrebidActions",
    component: PrebidActions,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/prebidreport",
    name: "PrebidReport",
    component: PrebidReport,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/help",
    name: "Help",
    component: Help,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    component: Maintenance,
    meta: {
      requireAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
