export default {
  msalConfig: {
    auth: {
      clientId: "0e6b28c3-10c5-4540-af9c-cf4af43d3910", // relates to the app registration
      authority: "https://login.microsoftonline.com/4ae48b41-0137-4599-8661-fc641fe77bea",
      redirectUri: window.location.origin + "/login/aad",
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: false,
    },
  },
  tokenTypes: {
    login: {
      scopes: ["User.Read"],
      extraScopesToConsent: [
        "User.ReadBasic.All",
        "https://arup.onmicrosoft.com/AIS/user_impersonation",
        "https://arup.onmicrosoft.com/d25e0336-e16b-4643-9ec9-6ee4a3b72c28/user_impersonation"
      ],
    },
    cds: {
      scopes: ["https://arup.onmicrosoft.com/AIS/user_impersonation"],
    },
    dip: {
      scopes: ["https://arup.onmicrosoft.com/d25e0336-e16b-4643-9ec9-6ee4a3b72c28/user_impersonation"],
    },
  },
}

