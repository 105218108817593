<template>
  <v-app>
    <msal-wrapper>
      <Header />

      <v-main>
        <v-container fluid>
          <router-view></router-view>
          <v-snackbar v-model="saveNotificationComputed" :timeout="1950">
            {{ text }}

            <template v-slot:action="{ attrs }">
              <v-btn
                color="blue"
                text
                v-bind="attrs"
                @click="saveNotificationComputed = false"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </v-container>
      </v-main>
    </msal-wrapper>
  </v-app>
</template>

<script>
import Header from "./components/Header";
import mixin from "./mixins/mixin.js";

export default {
  mixins: [mixin],
  name: "App",
  created() {},
  components: {
    Header,
  },
  watch: {
    isIdle() {
      // if (this.isIdle === true) {
      //   if (
      //     this.isPreparation ||
      //     this.isPreWork ||
      //     this.isActions ||
      //     this.isWorkshop ||
      //     this.isReport
      //   ) {
      //     mixin.methods.fullUpdateDIP(
      //       this.$store.state.plan,
      //       this.$store.state.ApiUri
      //     );
      //   }
      // }
      console.log("inactivity detected");
    },
  },
  mounted() {
    if (this.$session.exists("planData")) {
      this.$store.state.plan = this.$session.get("planData");
    }
    if (this.$store.state.maintenanceMode === "true") {
      this.$router.push({ name: `Maintenance` });
    }
  },
  computed: {
    // create a computed property that has a getter and setter
    saveNotificationComputed: {
      get() {
        return this.$store.state.saveNotification;
      },
      set(value) {
        this.$store.state.saveNotification = value;
      },
    },
    isPreparation() {
      return this.$route.name === "Preparation";
    },
    isPreWork() {
      return this.$route.name === "ResearchPreWork";
    },
    isWorkshop() {
      return this.$route.name === "Workshop";
    },
    isActions() {
      return this.$route.name === "Actions";
    },
    isReport() {
      return this.$route.name === "Report";
    },

    isIdle() {
      return this.$store.state.idleVue.isIdle;
    },
  },
  methods: {},

  data() {
    return {
      text: "Your changes have been saved.",
    };
  },
};
</script>
