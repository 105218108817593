import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {},
    projectInfo: {},
    lastModified: null,
    whiteboardTemplateLink: "https://arup-my.sharepoint.com/:wb:/p/emily_tinney/EYHdUB35HiFGspGxsGH_qeQBL5ZHHZkYfCUs3N-dox6vzQ?e=OFYttF",

    saveNotification: false,

    maintenanceMode: "false",

    // app config
    config: {},
    ApiUri: null,

    // prebid plan schema
    prebidPlan: {
      _id: null,
      Owner: {
        Name: null,
        Email: null,
      },
      ProjectName: null,
      JobNumber: null,
      Client: null,
      ProjectCode: null,
      Region: null,
      ProjectManager: {
        Name: null,
        Email: null,
      },
      ProjectDirector: {
        Name: null,
        Email: null,
      },
      Disciplines: [],
      SurveyId: null,
      Revision: null,
      Created: null,
      Modified: null,
      ModifiedBy: {
        Name: null,
        Email: null,
      },
      SchemaVersion: null,
      CompleteScore: null,
      PreBidPlan: {
        Lock: null,
        DigitalOpportunities: [],
        DigitalOpportunityActions: [],
        DigitalValueStories: [],
        ReadyDigitalServices: [],
        ContractChecked: null,
        ContractActions: [],
      },
    },

    // plan schema
    plan: {
      _id: null,
      Owner: {
        Name: null,
        Email: null,
      },
      ProjectName: null,
      JobNumber: null,
      Client: null,
      ProjectCode: null,
      Region: null,
      ProjectManager: {
        Name: null,
        Email: null,
      },
      ProjectDirector: {
        Name: null,
        Email: null,
      },
      Disciplines: [],
      SurveyId: null,
      Revision: null,
      Created: null,
      Modified: null,
      ModifiedBy: {
        Name: null,
        Email: null,
      },
      SchemaVersion: null,
      CompleteScore: null,
      DigitalPlan: {
        Lock: null,
        DigitalLead: {
          Name: null,
          Email: null,
        },
        DigitalFacilitator: {
          Name: null,
          Email: null,
        },
        DisciplineLeads: [],
        OtherAttendees: [],
        ClientAims: [],
        TeamAims: [],
        PainPoints: [],
        Contract: null,
        ContractPersonResponsible: null,
        ProjectSpecificChoices: [],
        CheckGlobalAutomationSharepoint: {
          Name: null,
          Email: null,
        },
        CheckGlobalADEPage: {
          Name: null,
          Email: null,
        },
        CheckSkillsNetworks: {
          Name: null,
          Email: null,
        },
        CheckArupProjects: {
          Name: null,
          Email: null,
        },
        CheckOtherResources: {
          Name: null,
          PersonResponsible: {
            Name: null,
            Email: null,
          },
        },
        ArupResourceFindings: [],
        ArupDigitalServices: [],
        ArupValueStories: [],
        LegacyArupResourceFindings: [],

        ExternalSoftwarePersonResponsible: {
          Name: null,
          Email: null,
        },
        ExternalSoftwareFindings: [],
        DataRequirements: [],
        DatasetStatus: null,
        DatasetContact: {
          Name: null,
          Email: null,
        },
        MetadataStandards: null,
        MetadataStatus: null,
        MetadataContact: {
          Name: null,
          Email: null,
        },
        EIRSupplied: null,
        EIRSuppliedTrue: null,
        EIRSuppliedFalse: {
          Name: null,
          Email: null,
        },
        BEPSupplied: null,
        BEPSuppliedTrueA: null,
        BEPSuppliedTrueB: null,
        BEPSuppliedFalseA: {
          Name: null,
          Email: null,
        },
        BEPSuppliedFalseB: {
          Name: null,
          Email: null,
        },
        LODAgreements: null,
        LODAgreementsFalse: {
          Name: null,
          Email: null,
        },
        OpenBIMApproach: null,
        OpenBIMApproachTrue: null,
        OpenBIMApproachFalse: {
          Name: null,
          Email: null,
        },
        BIMmmReview: null,
        BIMmmReviewTrue: null,
        BIMmmReviewFalse: {
          Name: null,
          Email: null,
        },
        GISRequirements: null,
        GISRequirementsTrueA: null,
        GISRequirementsTrueB: null,
        GISRequirementsTrueBTrue: null,
        GISRequirementsTrueBFalse: {
          Name: null,
          Email: null,
        },
        GISRequirementsFalse: {
          Name: null,
          Email: null,
        },
        LinkToWhiteboard: null,
        OtherActionsListSummary: [],
        ChallengesOpportunities: [],
        ActionPlan: [],
        PreparationComplete: 0,
        PreWorkComplete: 0,
        WorkshopComplete: 0,
        ActionsComplete: 0,
      },
    },
  },
  getters: {
    getMaintenanceMode: (state) => {
      return state.maintenanceMode;
    },
    saveNotification: (state) => {
      return state.saveNotification;
    },
    config: (state) => {
      return state.config;
    },
    plan: (state) => {
      return state.plan;
    },
    prebidPlan: (state) => {
      return state.prebidPlan;
    },
    automationToolEntries: state => {
      return state.plan.DigitalPlan.ArupResourceFindings.filter(obj =>
        JSON.stringify(Object.keys(obj)) !== `["Id","Name","UsefulLink","Discussion","PersonResponsible"]`
      );
    },
    legacyDataEntries: state => {
      return state.plan.DigitalPlan.ArupResourceFindings.filter(obj =>
        JSON.stringify(Object.keys(obj)) === `["Id","Name","UsefulLink","Discussion","PersonResponsible"]`
      );
    },
    digitalServiceEntries: state => {
      return state.plan.DigitalPlan.ArupDigitalServices;
    },
    valueStoryEntries: state => {
      return state.plan.DigitalPlan.ArupValueStories;
    },
    getWhiteboardTemplateLink: state => {
      return state.whiteboardTemplateLink;
    },
    getDatasetStatus: state => {
      return state.plan.DigitalPlan.DatasetStatus;
    },
    getDatasetContact: state => {
      return state.plan.DigitalPlan.DatasetContact;
    },
    getMetadataStandards: state => {
      return state.plan.DigitalPlan.MetadataStandards
    },
    getMetadataStatus: state => {
      return state.plan.DigitalPlan.MetadataStatus;
    },
    getMetadataContact: state => {
      return state.plan.DigitalPlan.MetadataContact;
    },

  },

  mutations: {
    updateMaintenanceMode(state, value) {
      if (value) state.maintenanceMode = value;
      else state.maintenanceMode = false;
    },
    updateSaveNotification(state, value) {
      if (value) state.saveNotification = value;
      else state.saveNotification = false;
    },
    updateUser(state, value) {
      if (value) state.user = value;
      else state.user = {};
    },
    updateConfig(state, value) {
      if (value) state.config = value;
      else state.config = {};
    },
    updateApiUri(state, value) {
      if (value) state.ApiUri = value;
      else state.ApiUri = null;
    },
    updateArupResourceFindings(state, newData) {
      state.plan.DigitalPlan.ArupResourceFindings = newData;
    },
    updateArupDigitalServices(state, newData) {
      state.plan.DigitalPlan.ArupDigitalServices = newData;
    },
    updateArupValueStories(state, newData) {
      state.plan.DigitalPlan.ArupValueStories = newData;
    },
    updateDatasetStatus(state, newData) {
      state.plan.DigitalPlan.DatasetStatus = newData;
    },
    updateMetadataStatus(state, newData) {
      state.plan.DigitalPlan.MetadataStatus = newData;
    },
    updateDatasetContact(state, newData) {
      state.plan.DigitalPlan.DatasetContact = newData;
    },
    updateMetadataContact(state, newData) {
      state.plan.DigitalPlan.MetadataContact = newData;
    },
    updateMetadataStandards(state, newData) {
      state.plan.DigitalPlan.MetadataStandards = newData;
    }
  },

  actions: {},
  modules: {},
});

