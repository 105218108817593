import Vue from "vue";

import axios from "axios";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import vuetify from "./plugins/vuetify";
import UUID from "vue-uuid";
import IdleVue from "idle-vue";
import VueSessionStorage from "vue-sessionstorage";
import Clipboard from "v-clipboard";
import msalAuthHandler from "vue-msal-wrapper"
import authConfig from "./authConfig";
import { CDSAuth } from "./plugins/cds.js";
import { DIPAuth } from "./plugins/dip.js";
import Logger from "./plugins/logger.js"


const eventsHub = new Vue();
const DIP_API_URI = "https://dip-uat-function-app.azurewebsites.net/api";
let DIP_MAINTENANCE_MODE = "";

let DIP_API_KEY = "hcyG_E-jbAnHe9STzbwlq5FOolXdkgW44UtooI3UsyzOAzFuAnzEtQ==";  
let REDIRECT_URI = "";

axios.defaults.headers.common["Content-Type"] = "application/json"; // find out what this means and the MSAL equivalent
axios.defaults.withCredentials = false; // find out what this means and the MSAL equivalent

if (DIP_API_URI.includes("https://")) {
  store.commit("updateApiUri", DIP_API_URI);
  if (DIP_API_URI == "https://dip-dev-function-app.azurewebsites.net/api") {
    REDIRECT_URI = "https://dip-dev.arup.com/refreshToken";
  }
  if (DIP_API_URI == "https://dip-uat-function-app.azurewebsites.net/api") {
    REDIRECT_URI = "https://dip-uat.arup.com/refreshToken";
  }
  // add the production one when ready
  if (DIP_API_URI == "https://dip-prod-function-app.azurewebsites.net/api") {
    REDIRECT_URI = "https://dip.arup.com/refreshToken";
  }
} else {
  // dev environment
  store.commit(
    "updateApiUri",
    "https://dip-dev-function-app.azurewebsites.net/api"
    // change to localhost
  );
  REDIRECT_URI = "http://localhost:8080";
  DIP_API_KEY = "J0eayYdUPlPKDpHLNgtmKLRBlex_Uv-WDxNNA1QD294aAzFuY_Qyrw=="
  DIP_MAINTENANCE_MODE = "false"; // set this to "true" to enable maintenance mode on localhost
}

store.commit(
  "updateMaintenanceMode",
  DIP_MAINTENANCE_MODE
);

console.log("DIP_MAINTENANCE_MODE: " + DIP_MAINTENANCE_MODE);

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 60000, // 1 minute
  startAtIdle: false,
});
Vue.use(msalAuthHandler, authConfig);
Vue.use(CDSAuth);
Vue.use(DIPAuth, { apiUri: DIP_API_URI, functionKey: DIP_API_KEY })
Vue.use(Clipboard);
Vue.use(Logger, { redirectURI: REDIRECT_URI })
Vue.use(VueSessionStorage);
Vue.config.productionTip = false;

Vue.use(UUID);
new Vue({
  router,
  store,
  vuetify,
  el: "#app",
  render: function (h) {
    return h(App);
  },
}).$mount("#app");


