<template>
  <div v-if="!maintenanceActive" class="v-system-bar">
    <a href="https://dip.arup.com/">
      <v-img
        alt="Arup Logo"
        class="shrink mr-2"
        contain
        src="../assets/logo-home-new--red.svg"
        transition="scale-transition"
        width="80"
      />
    </a>

    <v-spacer></v-spacer>

    <div class="v-btn-nav pr-10 pt-1">
      <!-- <a href="https://arupdigitalplan.azurewebsites.net/" target="_blank">
        Previous DIP site
      </a> -->

      <a
        href="https://app.powerbi.com/links/aMR79UCF1_?ctid=4ae48b41-0137-4599-8661-fc641fe77bea&pbi_source=linkShare"
        target="_blank"
        @click="disableRedirect"
      >
        DIP Stats
      </a>

      <!-- <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <a v-bind="attrs" v-on="on"> DIP Stats </a>
        </template>
        <span
          >DIP Stats are currently in maintenace, please try again later</span
        >
      </v-tooltip> -->

      <a :href="whiteboardTemplateLink" target="_blank">Workshop Template</a>

      <a @click="navigate('Help')">Help </a>

      <a
        href="https://feedback.arup.com/digital-inception-pl/35#"
        target="_blank"
        >Feedback
      </a>

      <!-- Below link needs replacing when confirmed. -->
      <a
        href="https://moodle.arup.com/course/view.php?id=5841%C2%A7ion%3D7"
        target="_blank"
        >DIP Training
      </a>

      <i class="v-btn-user"> </i>

      <span class="pl-5 hidden-sm-and-down">{{ login }}</span>
    </div>

    <v-dialog
      v-model="dialog"
      max-width="500px"
      @click:outside="dialog = false"
    >
      <v-card>
        <v-card-title class="headline">DIP Dashboard</v-card-title>

        <v-card-text>
          The DIP dashboard is currently down for maintenance.
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import mixin from "../mixins/mixin.js";

export default {
  mixins: [mixin],
  name: "App",

  components: {},

  data: () => ({
    login: "Not logged in.",
    dashboardDisabled: false, // set this to true to disable the dashboard button in the apps header
    dialog: false,
  }),
  created() {
    this.login = "Welcome " + this.$msal.getUser().name;
  },

  computed: {
    whiteboardTemplateLink: {
      get: function () {
        return this.$store.getters.getWhiteboardTemplateLink;
      },
    },
    maintenanceActive() {
      return this.$store.state.maintenanceMode === "true";
    },
  },

  methods: {
    navigate(routeName) {
      // this.fullUpdateDIP(this.$store.state.plan, this.$store.state.ApiUri);
      console.log(`navigation to ${routeName}...`);
      this.$router.push({ name: `${routeName}` });
    },
    disableRedirect(event) {
      if (this.dashboardDisabled) {
        event.preventDefault();
        this.dialog = true;
      }
    },
  },
};
</script>

<style src="../styles/main.scss" lang="scss"></style>
